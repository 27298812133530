import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import { arrayOf, bool, shape, string } from 'prop-types'
import { converText } from '../../../utils/textSlug'

import {
  BottomLine,
  Content,
  SubmenuNavigation,
  SubmenuNavigationItem,
  SubmenuNavigationList,
  RelativeContainer,
  NavigationLink
} from './styles'

function Submenu({ router, navItems, secondary, lastItemOnRight }) {
  const [currentIndex, setCurrentIndex] = useState(null)
  const navigationItems = []

  useEffect(() => {
    if (!secondary) {
      setCurrentIndex(0)
    }

    setCurrentIndex(findCurrentIndex())
  }, [])

  useEffect(() => {
    const { pathname } = router.location

    if (history.action === 'POP' && currentIndex === 0) {
      setCurrentIndex(findCurrentIndex())
      return
    }

    if (!secondary && pathname.includes('/instalacao')) {
      setCurrentIndex(3)
      return
    }

    if (currentIndex === null || currentIndex === -1) {
      setCurrentIndex(findCurrentIndex())
    }
  }, [router, history, navItems])

  const setActiveElement = index => {
    const isClient = typeof window !== 'undefined'
    const activeNavElement = navigationItems[index]
    if (activeNavElement && isClient) {
      return {
        bottomLinePosition: activeNavElement.offsetLeft,
        bottomLineWidth: activeNavElement.clientWidth
      }
    }
  }

  const findCurrentIndex = () => {
    const { pathname } = router.location
    if (router && navItems.length > 0) {
      const endOfLink = link => link && link.split('/')[link.split('/').length - 1]
      return navItems.findIndex(navItem => pathname.indexOf(endOfLink(navItem.link)) !== -1)
    }
  }

  const redirectPage = () => {
    window.open('https://veloewl.usezapay.com.br/', '_blank')
  }

  const { bottomLinePosition, bottomLineWidth } = setActiveElement(currentIndex) || {}
  const gtmLabel = 'item:'

  return (
    <SubmenuNavigation secondary={secondary} lastItemOnRight={lastItemOnRight}>
      <Content>
        <RelativeContainer>
          <SubmenuNavigationList>
            {navItems.map((item, index) => {
              const isActive = index === currentIndex
              return (
                <SubmenuNavigationItem
                  secondary={secondary}
                  active={isActive}
                  key={item.label}
                  innerRef={el => (navigationItems[index] = el)}
                  disabled={item.disabled}
                >
                  <NavigationLink
                    onClick={item.label === 'Parcelar débitos e multas' ? redirectPage : ''}
                    to={item.link}
                    id={item.id}
                    className="gtm-link-event"
                    data-gtm-event-category="veloe:area-logado:geral"
                    data-gtm-event-action="clique:menu-principal"
                    data-gtm-event-label={gtmLabel + converText(item.label)}
                  >
                    {item.label}
                  </NavigationLink>
                </SubmenuNavigationItem>
              )
            })}
          </SubmenuNavigationList>
          {!secondary && (
            <BottomLine
              style={{
                left: bottomLinePosition,
                width: bottomLineWidth
              }}
            />
          )}
        </RelativeContainer>
      </Content>
    </SubmenuNavigation>
  )
}

Submenu.defaultProps = {
  navItems: [],
  secondary: false
}

Submenu.propTypes = {
  secondary: bool,
  navItems: arrayOf(
    shape({
      external: bool,
      label: string,
      link: string,
      id: string
    })
  )
}

export default withRouter(Submenu)
